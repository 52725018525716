@charset "UTF-8";
/* Slider */
.slick-loading .slick-list {
	background: #fff url("../../img/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
	font-family: "slick";
	src: url("../../fonts/slick/slick.eot");
	src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	outline: none;
	background: transparent;
	color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	color: #fff;
	opacity: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;
}
[dir=rtl] .slick-prev {
	left: auto;
	right: -25px;
}
.slick-prev:before {
	content: "←";
}
[dir=rtl] .slick-prev:before {
	content: "→";
}

.slick-next {
	right: -25px;
}
[dir=rtl] .slick-next {
	left: -25px;
	right: auto;
}
.slick-next:before {
	content: "→";
}
[dir=rtl] .slick-next:before {
	content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	height: 20px;
	width: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}
.slick-dots li button {
	border: 0;
	background: transparent;
	display: block;
	height: 20px;
	width: 20px;
	outline: none;
	line-height: 0px;
	font-size: 0;
	color: transparent;
	padding: 5px;
	cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
	outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
	opacity: 1;
}
.slick-dots li button:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "•";
	width: 20px;
	height: 20px;
	font-family: "slick";
	font-size: 10px;
	line-height: 20px;
	text-align: center;
	color: #000;
	opacity: 0.25;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
	color: #000;
	opacity: 0.75;
}